<template>
  <div>
    <!-- <van-nav-bar
      :title="`${id ? '编辑' : '新建'}作业许可`"
      fixed
      left-arrow
      @click-left="$router.back()"
    /> -->
    <WorkticketHeader
      :title="`${id ? '编辑' : '新建'}作业许可`"
      :show-process="status === 14"
      :show-user-change="false"
      :ticket-status.sync="status"
    />
    <div class="form-box">
      <van-form ref="form" class="form">
        <van-field
          readonly
          clickable
          is-link
          :value="formBase.workJsaName"
          name="workJsaName"
          label="关联作业活动"
          placeholder="请选择"
          input-align="right"
          error-message-align="right"
          required
          :rules="[
            {
              required: true,
              message: ' ',
              trigger: 'onChange'
            }
          ]"
          @click="workJsaJump"
        />
        <van-field
          v-model="formBase.name"
          name="name"
          :label="projectTextConfig.TEXT_JSA_NAME"
          placeholder="请输入内容"
          maxlength="100"
          required
          clearable
          input-align="right"
          error-message-align="right"
          :rules="[
            {
              required: true,
              message: ' ',
              trigger: 'onChange'
            }
          ]"
        />
        <van-field
          v-model="formBase.departmentNames"
          name="departmentNames"
          label="所属部门"
          is-link
          required
          clearable
          placeholder="请选择"
          readonly
          input-align="right"
          error-message-align="right"
          :rules="[
            {
              required: true,
              message: ' ',
              trigger: 'onChange'
            }
          ]"
          @click="!tempSaveDisa && (departmentVisible = true)"
        />
        <van-field
          readonly
          clickable
          is-link
          :value="formBase.facilityName"
          name="facilityName"
          :label="projectTextConfig.TEXT_FACILITY_NAME"
          placeholder="请选择"
          required
          :disabled="tempSaveDisa"
          input-align="right"
          error-message-align="right"
          :rules="[
            {
              required: true,
              message: ' ',
              trigger: 'onChange'
            }
          ]"
          @click="!tempSaveDisa && (facilityVisible = true)"
        />
        <van-field
          :readonly="true"
          :value="formBase.riskAreaName"
          name="fenceName"
          label="电子围栏"
          placeholder=""
          input-align="right"
          error-message-align="right"
        />
        <!--        <van-field-->
        <!--          v-model="formBase.departmentNames"-->
        <!--          readonly-->
        <!--          clickable-->
        <!--          name="departmentNames"-->
        <!--          label="所属部门"-->
        <!--          placeholder=""-->
        <!--          required-->
        <!--          clearable-->
        <!--          :disabled="tempSaveDisa"-->
        <!--          input-align="right"-->
        <!--          error-message-align="right"-->
        <!--          :rules="[-->
        <!--            {-->
        <!--              required: true,-->
        <!--              message: ' ',-->
        <!--              trigger: 'onChange'-->
        <!--            }-->
        <!--          ]"-->
        <!--        />-->
        <!-- validator: cValidator, -->
        <van-field
          v-model="formBase.location"
          clickable
          name="location"
          :label="`具体${projectTextConfig.TEXT_WORK_LOCATION}`"
          placeholder="请输入内容"
          maxlength="100"
          clearable
          input-align="right"
          error-message-align="right"
          required
          :rules="[
            {
              required: true,
              message: ' ',
              trigger: 'onChange'
            },
            {
              pattern: /^[\da-zA-Z\u4e00-\u9fa5]*$/,
              message: '必须为中英文或数字',
              trigger: 'onChange'
            }
          ]"
        />
        <van-field
          readonly
          clickable
          is-link
          :value="
            (formBase.approverList || []).map(item => item.name).toString()
          "
          name="approverList"
          :label="TEXT_WORK_APPROVER"
          placeholder="请选择"
          required
          input-align="right"
          error-message-align="right"
          :rules="[
            {
              required: true,
              message: ' ',
              trigger: 'onChange'
            }
          ]"
          @click="workApprovalPersonClick(TEXT_WORK_APPROVER)"
        />
        <van-field
          readonly
          clickable
          is-link
          :value="
            (formBase.supervisorList || []).map(item => item.name).toString()
          "
          name="supervisorName"
          :label="TEXT_SAFETY_ANALYSIS_HEAD"
          placeholder="请选择"
          required
          input-align="right"
          error-message-align="right"
          :rules="[
            {
              required: true,
              message: ' ',
              trigger: 'onChange'
            }
          ]"
          @click="workApprovalPersonClick(TEXT_SAFETY_ANALYSIS_HEAD)"
        />
        <van-field
          readonly
          clickable
          is-link
          :value="workTypeListValueSwitch"
          name="workTypeList"
          label="作业类别"
          placeholder="请选择"
          required
          input-align="right"
          error-message-align="right"
          error
          :rules="[
            {
              required: true,
              message: ' ',
              trigger: 'onChange'
            }
          ]"
          @click="workTypeClick()"
        >
          <template #input>
            <span :style="{ color: workTypeColorSwitch }">请选择</span>
          </template>
        </van-field>
        <div class="work-type-list-box">
          <div
            v-for="(item, idx) of formBase.workTypeList"
            :key="idx"
            class="item-box"
          >
            <div class="left-box">
              <p class="type-name">{{ item.name }}</p>
              <p>
                {{ projectTextConfig.TEXT_WORK_DEPT }}：{{
                  item.contractorStatus == 1
                    ? item.deptShortName || item.deptName
                    : item.deptName
                }}
              </p>
              <p>所需资质：{{ (item.licenseTypeName || []).toString() }}</p>
              <p v-if="isBy">
                人员：
                {{
                  (item.workTypePerson || []).map(item => item.name).toString()
                }}
              </p>
            </div>
            <div>
              <span class="close-box" @click="workTypeListDel(idx)">×</span>
            </div>
          </div>
        </div>
        <van-field
          readonly
          clickable
          is-link
          :value="formBase.planStart"
          name="planStart"
          label="计划开始时间"
          placeholder="请选择"
          required
          input-align="right"
          error-message-align="right"
          :rules="[
            {
              required: true,
              message: ' ',
              trigger: 'onChange'
            },
            {
              validator: planStartValidator,
              message: '开始时间不能晚于结束时间',
              trigger: 'onChange'
            },
            {
              validator: planDateTimeoutValidator,
              message: () => `计划时间超出作业许可时限${planDateUnit}`,
              trigger: 'onChange'
            }
          ]"
          @click="planTimeClick('planStart')"
        />
        <van-field
          readonly
          clickable
          is-link
          :value="formBase.planEnd"
          name="planEnd"
          label="计划结束时间"
          placeholder="请选择"
          required
          input-align="right"
          error-message-align="right"
          :rules="[
            {
              required: true,
              message: ' ',
              trigger: 'onChange'
            },
            {
              validator: planEndValidator,
              message: '结束时间不能早于开始时间',
              trigger: 'onChange'
            },
            {
              validator: planDateTimeoutValidator,
              message: () => `计划时间超出作业许可时限${planDateUnit}`,
              trigger: 'onChange'
            }
          ]"
          @click="planTimeClick('planEnd')"
        />
        <MaintenanceHours
          v-model="formBase.maintenanceHours"
          :start-time="formBase.planStart"
          :end-time="formBase.planEnd"
        />
        <ProjectCategory :value.sync="formBase.projectType" :editable="false" />
        <ProjectRiskLevel :value.sync="formBase.riskLevel" :editable="false" />
        <ProjectLeader
          :value.sync="formBase.jsaProjectLeaderList"
          :phone-number.sync="formBase.jsaProjectLeaderPhone"
          :default-department="formBase.department"
        />
        <van-field
          v-if="isBy"
          :is-link="false"
          :clickable="false"
          :readonly="true"
          :required="false"
          :value="formBase.jsaProjectLeaderPhone"
          name="jsaProjectLeaderPhone"
          label="联系方式"
          placeholder="根据选择的项目负责人自动带入"
          input-align="right"
          error-message-align="right"
        />
        <SafetyLeader
          :value.sync="formBase.jsaSafetyLeaderList"
          :default-department="formBase.department"
        />
        <OverhaulLeader
          :value.sync="formBase.jsaOverhaulLeaderList"
          :default-department="formBase.department"
        />
        <van-field
          v-if="isBy"
          :is-link="false"
          :clickable="false"
          :readonly="true"
          :required="false"
          :value="formBase.changeFlag ? '是' : '否'"
          name="jsaProjectLeaderPhone"
          label="是否涉及变更"
          placeholder="--"
          input-align="right"
          error-message-align="right"
        />
        <ProjectSource :value.sync="formBase.projectSource" :editable="false" />
        <van-field
          v-if="isBy"
          :value="formBase.remarks"
          label="备注"
          type="textarea"
          maxlength="1000"
          placeholder=""
          input-align="right"
          error-message-align="right"
          autosize
          readonly
        />
        <!-- <van-field
          readonly
          :clickable="!tempSaveDisa"
          :is-link="!tempSaveDisa"
          name="relationList"
          label="关联作业票"
          placeholder="请选择"
          input-align="right"
          error-message-align="right"
          @click="!tempSaveDisa && (workLinkVisible = true)"
        /> -->
        <van-field
          readonly
          name="relationList"
          label="关联作业票"
          placeholder=""
          input-align="right"
          error-message-align="right"
        />
        <ul class="work-link-box">
          <li
            v-for="(item, idx) of formBase.relationList"
            :key="item.id"
            class="item-box"
          >
            <div class="left-box">
              <van-icon
                class="iconfont-lianjie"
                name="lianjie"
                class-prefix="iconfont"
                size="16"
              />
            </div>
            <div class="content">
              <span @click="workTicketRouterLink(item.id)">
                {{
                  `【${item.statusName}】${item.name}（${item.workType}）${item.code}`
                }}
              </span>
            </div>
            <div><span class="close-box" @click="CWKDelRow(idx)">×</span></div>
          </li>
        </ul>
        <van-field
          readonly
          clickable
          autosize
          is-link
          :value="formBase.proposerName"
          name="proposerName"
          label="申请人"
          placeholder="请选择人员"
          input-align="right"
          error-message-align="right"
          @click="showProposerPicker = true"
        >
        </van-field>
      </van-form>
      <div
        v-if="
          !id || (formBase.status === 14 && formBase.cuserId === userInfo.id)
        "
        class="footer-box"
      >
        <van-button
          class="confirm-btn"
          round
          size="small"
          type="info"
          @click="addWorkticket()"
          >提 交</van-button
        >
      </div>
    </div>
    <add-facility
      v-model="facilityMap"
      :visible="facilityVisible"
      auth="org"
      :title="projectTextConfig.TEXT_FACILITY_NAME"
      :facility="[16, 13, 14, 15, 31, 17]"
      activated="16"
      :department="formBase.department"
      :includes-department="true"
      @close="facilityVisible = false"
      @confirm="workAreaChange"
    />
    <select-person
      :title="selectPersonTitle"
      :visible="selectPersonVisible"
      :show-department-name="false"
      :show-phone-number="true"
      position="bottom"
      :include-children="true"
      :multiple="personMultipleSwitch"
      department-disabled-key="-"
      :choose-value="choosePersonSelect"
      @close="selectPersonVisible = false"
      @confirm="onPersonConfirm"
    />
    <select-person-by-tabs
      title="申请人(单选)"
      :visible="showProposerPicker"
      :show-department-name="false"
      :show-phone-number="true"
      position="bottom"
      :multiple="false"
      :is-department-auth="false"
      :choose-value="formBase.proposerList"
      @close="showProposerPicker = false"
      @confirm="onProposerConfirm"
    />
    <van-popup v-model="showTimePicker" position="bottom">
      <van-datetime-picker
        v-model="initTime"
        type="datetime"
        @confirm="onDateimeConfirm"
        @cancel="showTimePicker = false"
      />
    </van-popup>
    <!-- 添加作业类别 -->
    <van-popup
      v-model="showWorkTypePicker"
      class="work-type-popup"
      position="bottom"
      @close="workTypePickerClose()"
    >
      <section class="header">
        <van-icon
          class="close-icon"
          name="mengcengguanbi"
          class-prefix="iconfont"
          @click.native="closeIconClick()"
        />
        <span class="title">添加作业类别</span>
      </section>
      <section class="content">
        <van-form ref="addWorkTypeForm" class="add-work-type-form-box">
          <van-collapse v-model="addWorkTypeActiveNames">
            <van-collapse-item
              v-for="(item, idx) of workTypeList"
              :key="idx"
              class="collapse-item-box"
              :title="item.name"
              :name="idx"
              :is-link="false"
              :border="false"
            >
              <template #title>
                <div>
                  <van-checkbox
                    v-model="item.choose"
                    class="checkbox-box"
                    shape="square"
                    icon-size="16px"
                    @change="val => workTypeCheckboxChange(val, idx)"
                  ></van-checkbox>
                  <span class="checkbox-name">{{ item.name }}</span>
                </div>
              </template>
              <van-field
                readonly
                clickable
                is-link
                :value="item.deptName"
                name="deptName"
                :label="projectTextConfig.TEXT_WORK_DEPT"
                placeholder="请选择"
                required
                :disabled="tempSaveDisa"
                input-align="right"
                error-message-align="right"
                :rules="[
                  {
                    required: item.choose,
                    message: ' ',
                    trigger: 'onChange'
                  }
                ]"
                @click="!tempSaveDisa && workUnitClick(item, idx)"
              />
              <van-field
                readonly
                clickable
                is-link
                :value="item.licenseTypeName.toString()"
                name="licenseTypeName"
                label="特种作业人员所需资质"
                placeholder="请选择"
                input-align="right"
                error-message-align="right"
                :disabled="item.specialJobType === '5' || tempSaveDisa"
                @click="!tempSaveDisa && licenseTypeClick(item, idx)"
              />
              <van-field
                v-if="isBy"
                readonly
                name="workTypePerson"
                label="人员"
                placeholder="--"
                input-align="right"
                error-message-align="right"
                :disabled="tempSaveDisa"
                :value="
                  (item.workTypePerson || []).map(item => item.name).toString()
                "
              />
            </van-collapse-item>
          </van-collapse>
        </van-form>
      </section>
      <div class="confirm-btn-box">
        <van-button
          class="confirm-btn"
          round
          size="small"
          type="info"
          @click="addWorkTypeConfirm()"
          >确 定</van-button
        >
      </div>
    </van-popup>
    <!-- 作业单位选择 -->
    <van-popup
      v-model="showWorkUnitPicker"
      class="work-unit-popup"
      position="bottom"
      @close="showWorkUnitPicker = false"
    >
      <div class="content">
        <van-icon
          class="close-icon"
          name="mengcengguanbi"
          class-prefix="iconfont"
          @click.native="showWorkUnitPicker = false"
        />
        <div class="confirm-btn-box">
          <van-button
            class="confirm-btn"
            round
            size="small"
            type="info"
            @click="workUnitConfirm()"
            >确 定</van-button
          >
        </div>
        <van-tabs v-model="workUnitActive">
          <van-tab name="1" title="承包商">
            <div class="contractors-list">
              <div
                v-for="item in contractorsSelectData"
                :key="item.value"
                class="contractors-list-item"
                :class="{
                  active: item.value === contractorsChooseItem.value,
                  'active-bgc': item.value === contractorsChooseItem.value
                }"
                @click="contractorsChooseClick(item)"
              >
                <span>{{ item.label }}</span>
              </div>
            </div>
          </van-tab>
          <van-tab name="0" title="企业部门">
            <div class="org-tree-container">
              <org-tree
                v-model="deptSelectValue"
                :data="deptTreeData"
                label-key="label"
                value-key="id"
                disabled-key="--"
                disabled-toast="没有对应部门的数据权限"
                @input="deptTreeInput"
              />
            </div>
          </van-tab>
        </van-tabs>
      </div>
    </van-popup>
    <!-- 作业资质选择 -->
    <van-popup
      v-model="showLicensePicker"
      class="work-unit-popup"
      position="bottom"
      @close="showLicensePicker = false"
    >
      <div class="content">
        <p class="title">特种作业人员所需资质</p>
        <van-icon
          class="close-icon"
          name="mengcengguanbi"
          class-prefix="iconfont"
          @click.native="showLicensePicker = false"
        />
        <div class="confirm-btn-box">
          <van-button
            class="confirm-btn"
            round
            size="small"
            type="info"
            @click="licenseTypeConfirm()"
            >确 定</van-button
          >
        </div>
        <div class="org-tree-container">
          <org-tree
            v-model="licenseSelectValue"
            :data="licenseDictTreeData"
            label-key="name"
            value-key="id"
            disabled-key="disabled"
            choosable-key="choosable"
            @input="licenseTypeInput"
          />
        </div>
      </div>
    </van-popup>
    <!-- 关联作业票选择 -->
    <work-ticket-select
      :visible="workLinkVisible"
      @close="workLinkVisible = false"
      @onConfirm="workTicketSelectConfirm"
    />
    <cascade-department
      v-model="formBase.department"
      :visible="departmentVisible"
      disabled-key="--"
      @close="departmentVisible = false"
      @confirm="onTreeConfirm"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import OrgTree from "@/components/OrgTree";
import workTicketSelect from "@/components/workTicketSelect";
import { getdeptInfoByFacilityId } from "@/api/basics/workticket";
import { parseTime } from "@/utils";
import { getSelectWorkTypes, addWorkTickets } from "@/api/psm";
import { getSltContractorsShortName } from "@/api/base";
import { getSelectTreeDept } from "@/api/authorization";
import { getLicenseDict } from "@/api/psm";
import { treeForEach, deepClone } from "@/utils/index";
import {
  getWorkJsaInfo,
  getWorkTypeList,
  getWorkticketBaseInfo,
  updateWorkTicketBaseInfo,
  getRelationListByJsaId,
  validateWorkTypeVacation,
  validateWorkTime
} from "@/api/workticket";
import { getDeptNameCascadeByDeptCode } from "@/api/authorization";
import WorkticketHeader from "@/components/header/WorkticketHeader";
import projectTextConfig from "@/utils/projectTextConfig";
import MaintenanceHours from "./components/MaintenanceHours";
import ProjectCategory from "./components/ProjectCategory";
import ProjectRiskLevel from "./components/ProjectRiskLevel";
import ProjectLeader from "./components/ProjectLeader";
import SafetyLeader from "./components/SafetyLeader";
import OverhaulLeader from "./components/OverhaulLeader";
import ProjectSource from "./components/ProjectSource";

export default {
  name: "WorkticketAdd",
  components: {
    OrgTree,
    workTicketSelect,
    WorkticketHeader,
    MaintenanceHours,
    ProjectCategory,
    ProjectRiskLevel,
    ProjectLeader,
    SafetyLeader,
    OverhaulLeader,
    ProjectSource
  },
  data() {
    return {
      projectTextConfig,
      projectName: window.globalConfig.VUE_APP_NAME,
      id: this.$route.params.id,
      status: "",
      departmentVisible: false,
      formBase: {
        status: "0",
        workJsaId: "", // 关联作业活动
        workJsaName: "",
        name: "", // 作业名称
        orgCode: this.$store.state.login.userInfo.orgCode,
        department: "",
        departmentName: "",
        departmentNames: "", // 部门所属级联
        facilityId: "", // 作业区域id
        facilityType: "", // 作业区域类型
        facilityName: "", // 作业区域名称
        location: "", // 具体作业位置
        approverList: [], // 作业批准人
        approverName: [],
        // supervisor: "", // 安全分析负责人
        supervisorList: [], // 安全分析负责人
        supervisorName: "",
        planStart: "", // 计划开始时间
        planEnd: "", // 计划结束时间
        realStart: "", // 实际开始时间
        realEnd: "", // 实际完成时间
        // proposer: this.$store.state.login.userInfo.id, // 申请人
        proposerName: this.$store.state.login.userInfo.nickName,
        proposerList: [
          {
            value: this.$store.state.login.userInfo.id,
            label: this.$store.state.login.userInfo.nickName,
            id: this.$store.state.login.userInfo.id,
            name: this.$store.state.login.userInfo.nickName,
            type: "0"
          }
        ],
        ctime: "", // 申请时间
        workTypeList: [
          // {
          //   gasMonitor: 1,
          //   contractorStatus: 1, // 是否承包商0：否，1：是
          //   specialJobType: "", // 作业类型
          //   licenseType: [], // 特种作业人员所需资质
          //   name: "", // 作业类型名称
          //   id: "", // 作业类型id
          //   deptName: "", // 所属单位名称
          //   deptShortName: "", // 所属单位简称，只有承包商存在
          //   deptCode: "", // 所属单位code
          //   specialId: "" // 作业许可id
          // }
        ],
        relationList: [],
        maintenanceHours: 0, // 检维修工时
        projectType: "", // 项目类别
        riskLevel: "", // 风险等级
        jsaProjectLeaderList: [], // 项目负责人
        jsaProjectLeaderPhone: "", // 联系方式
        jsaSafetyLeaderList: [], // 安全负责人
        jsaOverhaulLeaderList: [], // 检修负责人
        changeFlag: 1, // 是否涉及变更
        projectSource: "", // 项目来源
        remarks: "" // 备注
      },
      tempFormBase: {},
      selectPersonTitle: projectTextConfig.TEXT_WORK_APPROVER,
      selectPersonVisible: false,
      choosePersonSelect: [],
      selectPersonObj: {
        [projectTextConfig.TEXT_WORK_APPROVER]: {
          valueKey: "approverList",
          labelKey: "approverName"
        },
        [projectTextConfig.TEXT_SAFETY_ANALYSIS_HEAD]: {
          valueKey: "supervisorList",
          labelKey: "supervisorName"
        }
      },
      showTimePicker: false,
      initTime: "",
      planTimeCurProp: "",
      showWorkTypePicker: false,
      workTypeList: [],
      addWorkTypeActiveNames: [],
      workUnitActive: 1,
      showWorkUnitPicker: false,
      contractorsSelectData: [],
      contractorsChooseItem: {},
      curWorkUnitIdx: 0,
      curLicenseTypeIdx: 0,
      deptTreeData: [],
      deptSelectValue: {},
      showLicensePicker: false,
      licenseDictTreeData: [],
      licenseSelectValue: {},
      licenseSelectValueOld: {},
      workTypeColorTrigger: false,
      workLinkVisible: false,
      tempSaveDisa: false,
      tempPlanDateRange: [],
      planDateUnit: "", // 计划时间超出作业许可时限单位
      facilityMap: {
        label: "",
        value: "",
        type: ""
      },
      facilityVisible: false,
      showProposerPicker: false,
      // proposerList: [
      //   {
      //     value: this.$store.state.login.userInfo.id,
      //     label: this.$store.state.login.userInfo.nickName,
      //     type: "0"
      //   }
      // ]
      isRH: window.globalConfig.VUE_APP_WORK_RH === "RH",
      fieldProps: {
        border: true,
        placeholder: "请输入",
        clearable: true,
        "input-align": "right",
        "error-message-align": "right"
      },
      workTypePersonMap: {} // 所有作业类别对应的人员数组
    };
  },
  computed: {
    ...mapState({
      userInfo: state => state.login.userInfo
    }),
    workTypeColorSwitch() {
      if (this.workTypeColorTrigger && !this.formBase.workTypeList.length) {
        return "#ee0a24";
      }
      return "#ccc";
    },
    workTypeListValueSwitch() {
      // 值只作为是否填写的校验作用
      return this.formBase.workTypeList.length ? "123" : "";
    },
    personMultipleSwitch() {
      return this.selectPersonTitle === this.TEXT_WORK_APPROVER;
    }
  },
  watch: {
    $route: {
      async handler(to, from) {
        if (from.name === "WBSATempSelect") {
          let temp = window.sessionStorage.getItem("tempByAdd");
          temp = temp ? JSON.parse(temp) : null;
          window.sessionStorage.removeItem("tempByAdd");
          if (!temp) return;
          const info = await getWorkJsaInfo(temp.id);
          const data2 = await getDeptNameCascadeByDeptCode(info.department);
          const relationList = await getRelationListByJsaId({
            orgCode: this.userInfo.orgCode,
            workJsaId: info.id
          });
          this.workTypeList = [];
          this.facilityMap = {
            label: info.facilityName,
            value: info.facilityId,
            type: info.facilityType
          };
          this.formBase = {
            ...this.formBase,
            riskAreaId: info.riskAreaId,
            riskAreaName: info.riskAreaName,
            workJsaId: info.id,
            workJsaName: info.jsaName,
            name: info.jsaName,
            facilityId: info.facilityId,
            facilityType: info.facilityType,
            facilityName: info.facilityName,
            department: info.department,
            departmentName: info.departmentName,
            departmentNames: this.processDepartmentNames(data2),
            location: info.workLocation,
            workTypeList: [],
            planStart: info.planStart,
            planEnd: info.planEnd,
            relationList: relationList.map(i => {
              return {
                ...i,
                specialList: i.workType ? i.workType.split(",") : []
              };
            }),
            maintenanceHours: info.maintenanceHours,
            projectType: info.projectType,
            riskLevel: info.riskLevel,
            jsaProjectLeaderList: info.jsaProjectLeaderList,
            jsaProjectLeaderPhone: info.jsaProjectLeaderPhone,
            jsaSafetyLeaderList: info.jsaSafetyLeaderList,
            jsaOverhaulLeaderList: info.jsaOverhaulLeaderList,
            changeFlag: info.changeFlag,
            projectSource: info.projectSource,
            remarks: info.remarks
          };
          this.tempFormBase = JSON.parse(JSON.stringify(this.formBase));
          this.tempSaveDisa = true;
          if (this.isBy) {
            this.validateWorkTime();
          }
        }
      }
      // immediate: false
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.formBase.department = this.userInfo.department;
    this.formBase.departmentName = this.userInfo.departmentName;
    this.formBase.departmentNames = this.userInfo.departmentName;
    this.initPage();
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  methods: {
    onTreeConfirm(cur, { tree }) {
      let parent = null;
      treeForEach(tree, node => {
        if (node.id === cur._parendId) parent = node;
      });
      this.formBase.departmentName = cur.label;
      this.formBase.departmentNames = parent
        ? parent.label + "-" + cur.label
        : cur.label;
      this.facilityMap.label = this.formBase.facilityName = "";
      this.facilityMap.type = this.formBase.facilityType = "";
      this.facilityMap.value = this.formBase.facilityId = "";
    },
    onProposerConfirm(ids, list) {
      // this.proposerList = list;
      // this.formBase.proposer = ids?.[0] || "";
      this.formBase.proposerName = list?.[0]?.label || "";
      this.formBase.proposerList = list.map(i => {
        i.id = i.value;
        i.name = i.label;
        i.type = i._mechanismType ?? "0";
        return i;
      });
    },
    processDepartmentNames(data = []) {
      return data.slice(-2).join("-");
    },
    async initPage() {
      if (!this.id) return;
      const info = await getWorkticketBaseInfo(this.id);
      this.status = info.status;
      this.facilityMap = {
        label: info.facilityName,
        value: info.facilityId,
        type: info.facilityType
      };
      this.formBase = {
        ...this.formBase,
        ...info,
        // cuserId: info.cuserId,
        // id: info.id,
        // code: info.code,
        // workJsaId: info.workJsaId,
        // workJsaName: info.workJsaName,
        // name: info.name,
        // facilityId: info.facilityId,
        // facilityType: info.facilityType,
        // facilityName: info.facilityName,
        // department: info.department,
        // departmentName: info.departmentName,
        departmentNames: this.processDepartmentNames(info.departmentNames),
        // location: info.location,
        // approver: info.approver, // 作业批准人
        // approverName: info.approverName,
        // proposer: info.proposer, // 申请人
        // proposerName: info.proposerName, // 申请人姓名
        // proposerTel: info.proposerTel, // 申请人电话
        // supervisor: info.supervisor, // 安全分析负责人
        // supervisorName: info.supervisorName,
        // workTypeList: info.workTypeList,
        // planStart: info.planStart,
        // planEnd: info.planEnd,
        proposerList: info.proposerList.map(i => {
          i.value = i.id;
          i.label = i.name;
          return i;
        }),
        relationList: info.relationList.map(i => {
          i.workType = (i.specialList || []).toString();
          return i;
        })
      };

      this.tempPlanDateRange = [info.planStart, info.planEnd];
      if (info.workJsaId) {
        const tempFormBase = await getWorkJsaInfo(this.formBase.workJsaId);
        this.tempFormBase.planStart = tempFormBase.planStart;
        this.tempFormBase.planEnd = tempFormBase.planEnd;
        if (this.isBy) {
          let arrMap = {};
          tempFormBase.workTypeList.forEach(item => {
            arrMap[item.workTypeId] = (item.workerList || []).filter(
              i => i.personType === "28"
            );
          });
          this.formBase.workTypeList = this.formBase.workTypeList.map(item => {
            item.workTypePerson = arrMap[item.id] || [];
            return item;
          });
          this.workTypePersonMap = arrMap;
        }
      }
      this.tempSaveDisa = !!info.workJsaId;
    },
    async workAreaChange(facilityObj) {
      if (!facilityObj.value) {
        this.formBase.facilityId = "";
        this.formBase.facilityName = "";
        this.formBase.facilityType = "";
        // this.formBase.departmentName = "";
        // this.formBase.departmentNames = "";
        // this.formBase.department = "";
        return;
      }
      this.formBase.facilityId = facilityObj.value;
      this.formBase.facilityName = facilityObj.label;
      this.formBase.facilityType = facilityObj.type;
      const params = {
        facilitiesId: facilityObj.value
      };
      const deptInfo = await getdeptInfoByFacilityId(params);
      this.formBase.departmentName = deptInfo.departmentName;
      this.formBase.department = deptInfo.department;
      const data2 = await getDeptNameCascadeByDeptCode(deptInfo.department);
      this.formBase.departmentNames = this.processDepartmentNames(data2);
    },
    workApprovalPersonClick(title) {
      this.selectPersonTitle = title;
      const keyObj = this.selectPersonObj[title];
      let value = this.formBase[keyObj.valueKey];
      // let label = this.formBase[keyObj.labelKey];
      // if (typeof value === "string") {
      //   // 转换为多选数据统一处理
      //   value = [value];
      //   label = [label];
      // }
      // this.choosePersonSelect = (value || []).map((item, idx) => {
      //   return {
      //     value: item,
      //     label: label[idx] || item
      //   };
      // });
      this.choosePersonSelect = (value || []).map((item, idx) => {
        return {
          value: item.id,
          label: item.name
        };
      });
      this.selectPersonVisible = true;
    },
    onPersonConfirm(ids, list) {
      const keyObj = this.selectPersonObj[this.selectPersonTitle];
      // 默认多选数据
      // let value = ids;
      // let label = list.map(item => item.label);
      // if (this.selectPersonTitle === this.TEXT_SAFETY_ANALYSIS_HEAD) {
      //   value = ids[0] || "";
      //   label = label[0] || "";
      // }
      // this.formBase[keyObj.valueKey] = value;
      // this.formBase[keyObj.labelKey] = label;
      this.formBase[keyObj.valueKey] = list.map(i => {
        i.id = i.value;
        i.name = i.label;
        i.type = "0";
        return i;
      });
      const label = list.map(i => i.label);
      this.formBase[keyObj.labelKey] =
        this.selectPersonTitle === this.TEXT_SAFETY_ANALYSIS_HEAD
          ? label.toString() || ""
          : label;
    },
    planTimeClick(prop) {
      this.planTimeCurProp = prop;
      const datetime = this.formBase[prop];
      this.initTime = datetime ? new Date(datetime) : new Date();
      this.showTimePicker = true;
    },
    onDateimeConfirm(time) {
      this.formBase[this.planTimeCurProp] = parseTime(
        time,
        "{y}-{m}-{d} {h}:{i}:00"
      );
      this.showTimePicker = false;
      if (this.isBy) {
        this.validateWorkTypeVacation(this.planTimeCurProp);
        this.validateWorkTime();
      }
    },
    planEndValidator(val) {
      if (!val || !this.formBase.planStart) return true;
      const endTime = Date.parse(new Date(val));
      const startTime = Date.parse(new Date(this.formBase.planStart));
      return endTime > startTime;
    },
    planStartValidator(val) {
      if (!val || !this.formBase.planEnd) return true;
      const startTime = Date.parse(new Date(val));
      const endTime = Date.parse(new Date(this.formBase.planEnd));
      return endTime > startTime;
    },
    planDateTimeoutValidator(val) {
      const start = this.formBase.planStart;
      const end = this.formBase.planEnd;
      if (Date.parse(val) < Date.parse(this.tempFormBase.planStart)) {
        return false;
      }
      if (Date.parse(val) > Date.parse(this.tempFormBase.planEnd)) {
        return false;
      }
      const findTimesList = this.formBase.workTypeList.filter(
        item => item.times
      );
      if (!start || !end || !findTimesList.length) return true;
      this.planDateUnit =
        "(" +
        findTimesList[0].times +
        (findTimesList[0].timesUnit === "d" ? "天)" : "小时)");
      const obj = {
        h: 3600000,
        d: 86400000
      };
      const millisecondList = findTimesList
        .map(item => Number(item.times) * obj[item.timesUnit])
        .sort((a, b) => a - b);
      if (!millisecondList[0] && millisecondList[0] !== 0) {
        millisecondList.push(99999999999999);
      }
      const planTime = Date.parse(end) - Date.parse(start);
      let diff = millisecondList[0];
      if (this.formBase.workJsaId) {
        const tempMillisecond =
          Date.parse(this.tempPlanDateRange[1]) -
          Date.parse(this.tempPlanDateRange[0]);
        diff =
          tempMillisecond <= millisecondList[0]
            ? tempMillisecond
            : millisecondList[0];
      }
      return planTime <= diff;
    },
    async workTypeClick() {
      let res = [];
      let arrMap = {};
      if (this.formBase.workJsaId) {
        const info = await getWorkJsaInfo(this.formBase.workJsaId);
        const params = {
          workTypeSpecialList: info.workTypeList
        };
        res = await getWorkTypeList(params);
        this.tempPlanDateRange = [info.planStart, info.planEnd];
        if (this.isBy) {
          info.workTypeList.forEach(item => {
            arrMap[item.workTypeId] = (item.workerList || []).filter(
              i => i.personType === "28"
            );
          });
          this.workTypePersonMap = arrMap;
        }
      } else {
        res = await getSelectWorkTypes(this.userInfo.orgCode);
      }
      const arr = Array.isArray(res) ? res : [];
      this.workTypeList = arr.map(item => {
        return {
          gasMonitor: item.gasMonitor || "",
          // 是否承包商0：否，1：是
          contractorStatus:
            item.contractorStatus === 0 ? 0 : item.contractorStatus || 1,
          specialJobType: item.specialJobType, // 作业类型
          // 特种作业人员所需资质
          licenseType: this.formBase.workJsaId
            ? [item.licenseType.toString()] || []
            : item.licenseType,
          licenseTypeName: item.licenseTypeName || [],
          name: item.name, // 作业类型名称
          id: item.id, // 作业类型id
          deptName: item.deptName || "", // 所属单位名称
          deptShortName: item.deptName || "", // 所属单位简称，承包商有
          deptCode: item.deptCode || "", // 所属单位code
          specialId: "", // 作业许可id
          times: item.times, // 作业时限
          timesUnit: item.timesUnit,
          choose: false,
          workTypePerson: arrMap[item.id] || []
        };
      });
      this.workTypeColorTrigger = false;
      this.addWorkTypeActiveNames = [];
      this.showWorkTypePicker = true;
    },
    closeIconClick() {
      this.showWorkTypePicker = false;
    },
    workUnitClick(rowData, idx) {
      this.curWorkUnitIdx = idx;
      this.showWorkUnitPicker = true;
      this.workUnitActive = rowData.contractorStatus;
      this.contractorsChooseItem = {};
      this.deptSelectValue = {};
      const obj = {
        label: rowData.deptName,
        value: rowData.deptCode,
        id: rowData.deptCode,
        deptShortName: rowData.deptShortName || ""
      };
      const propKey = {
        "1": "contractorsChooseItem",
        "0": "deptSelectValue"
      };
      const prop = propKey[this.workUnitActive];
      this[prop] = obj;
      this.getDepartmentTreeData();
      this.getContractorsSelectData();
    },
    licenseTypeClick(rowData, idx) {
      if (rowData.specialJobType === "5") return;
      this.curLicenseTypeIdx = idx;
      this.showLicensePicker = true;
      this.licenseSelectValue = {
        name: rowData.licenseTypeName[0] || "",
        id: Array.isArray(rowData.licenseType)
          ? rowData.licenseType[0] || ""
          : rowData.licenseType || ""
      };
      this.licenseSelectValueOld = this.licenseSelectValue.id
        ? { ...this.licenseSelectValue }
        : {};
      this.getLicenseDict();
    },
    async getLicenseDict() {
      const params = {
        orgCode: this.$store.state.login.userInfo.orgCode
      };
      const arr = await getLicenseDict(params);
      treeForEach(arr, item => {
        item.filtered = true;
        item.value = item.id;
        item.choosable = item.children ? false : true;
      });
      this.licenseDictTreeData = arr;
    },
    async getContractorsSelectData() {
      const params = {
        orgCodeInList: this.userInfo.orgCode,
        state: 0
      };
      this.contractorsSelectData = await getSltContractorsShortName(params);
    },
    async getDepartmentTreeData() {
      try {
        const arr = await getSelectTreeDept(this.userInfo.orgCode);
        treeForEach(arr, item => {
          item.filtered = true;
          item.value = item.id;
        });
        this.deptTreeData = arr;
      } catch (e) {
        console.log("getDepartmentTreeData -> e", e);
      }
    },
    contractorsChooseClick(rowData) {
      this.contractorsChooseItem = rowData;
      this.deptSelectValue = {};
    },
    deptTreeInput(item) {
      this.contractorsChooseItem = {};
    },
    workUnitConfirm() {
      const idx = this.curWorkUnitIdx;
      const propKey = {
        "1": "contractorsChooseItem",
        "0": "deptSelectValue"
      };
      const prop = propKey[this.contractorsChooseItem.value ? "1" : "0"];
      this.workTypeList[idx].deptName = this[prop].label;
      this.workTypeList[idx].deptCode = this[prop].value;
      this.workTypeList[idx].contractorStatus = this.contractorsChooseItem.value
        ? "1"
        : "0";
      this.workTypeList[idx].deptShortName = this.contractorsChooseItem.value
        ? this[prop].shortName
        : "";
      this.showWorkUnitPicker = false;
    },
    licenseTypeConfirm() {
      const idx = this.curLicenseTypeIdx;
      this.workTypeList[idx].licenseType = this.licenseSelectValue.id
        ? [this.licenseSelectValue.id]
        : [];
      this.workTypeList[idx].licenseTypeName = this.licenseSelectValue.name
        ? [this.licenseSelectValue.name]
        : [];
      this.showLicensePicker = false;
    },
    licenseTypeInput(item) {
      if (item.id === this.licenseSelectValueOld.id) {
        this.licenseSelectValue = {};
        this.licenseSelectValueOld = {};
        return;
      }
      this.licenseSelectValueOld = item;
    },
    addWorkTypeConfirm() {
      if ((this.isRt || this.isBy) && this.formBase.workTypeList.length > 0) {
        return this.$toast.fail("已存在作业类别，请先删除");
      }
      const find = this.workTypeList.filter(item => item.choose);
      if (!find.length) return this.$toast.fail("请添加作业类别!");
      if ((this.isRt || this.isBy) && find.length > 1) {
        return this.$toast.fail("只能添加一个作业类别!");
      }
      this.$refs.addWorkTypeForm
        .validate()
        .then(res => {
          this.formBase.workTypeList = [
            ...this.formBase.workTypeList,
            ...this.workTypeList.filter(item => item.choose)
          ];
          this.showWorkTypePicker = false;
          this.workTypeColorTrigger = true;
          if (this.isBy) {
            this.validateWorkTypeVacation("workType");
            this.validateWorkTime();
          }
        })
        .catch(e => {
          this.$toast.fail(`请选择${projectTextConfig.TEXT_WORK_DEPT}`);
        });
    },
    workTypeCheckboxChange(val, idx) {
      if (val) {
        if (!this.addWorkTypeActiveNames.includes(idx)) {
          this.addWorkTypeActiveNames.push(idx);
        }
        return;
      }
      // const findIdx = this.addWorkTypeActiveNames.findIndex(idx);
      // this.addWorkTypeActiveNames.splice(findIdx, 0);
    },
    async addWorkticket() {
      this.workTypeColorTrigger = true;
      await this.$refs.form.validate();
      if (this.workTypeColorTrigger && this.formBase.workTypeList.length) {
        this.$dialog
          .confirm({
            message: "确认提交？"
          })
          .then(async () => {
            try {
              const params = deepClone(this.formBase);
              // params.approverInfo = [{ id: params.approver[0], type: "0" }];
              // params.supervisorInfo = [{ id: params.supervisor, type: "0" }];
              params.relationList = params.relationList.map(item => item.id);
              params.workTypeList = params.workTypeList.map(item => {
                item.hasRescue = this.isRH;
                item.workerList = [
                  ...(item.workerList || []),
                  ...(item.workTypePerson || [])
                ];
                return item;
              });
              let id = "";
              if (this.formBase.id) {
                await updateWorkTicketBaseInfo(params);
              } else {
                id = await addWorkTickets(params);
              }
              await this.$router.replace({
                name: "worktickeDetail",
                params: {
                  id: id || this.formBase.id,
                  isRouterReplace: true
                }
              });
            } catch (e) {
              console.log(e);
            }
          });
      }
    },
    workTypeListDel(idx) {
      this.formBase.workTypeList.splice(idx, 1);
    },
    workTypePickerClose() {
      this.workTypeColorTrigger = true;
      this.showWorkTypePicker = false;
    },
    CWKDelRow(idx) {
      this.formBase.relationList.splice(idx, 1);
    },
    workTicketSelectConfirm(checkedList) {
      const totalLen = this.formBase.relationList.length + checkedList.length;
      if (totalLen > 10) {
        return this.$dialog
          .alert({
            title: "提示",
            message: "最多可关联10个作业"
          })
          .then(() => {
            // on close
          });
      }
      const curSelectedFilterRes = checkedList.filter(
        curItem =>
          !this.formBase.relationList.find(
            alreadyItem => alreadyItem.id === curItem.id
          )
      );
      const allData = this.formBase.relationList.concat(curSelectedFilterRes);
      this.formBase.relationList =
        allData.length <= 10 ? allData : allData.splice(10);
      this.workLinkVisible = false;
    },
    workTicketRouterLink(id) {
      this.$router.push({
        name: "worktickeDetail",
        params: { id }
      });
    },
    workJsaJump() {
      this.$router.push("/WBSATempSelect");
    },
    async validateWorkTypeVacation(type) {
      const { workTypeList, planStart, planEnd } = this.formBase;
      const workTypes = (workTypeList || []).filter(item => item.id);
      if (workTypes.length && planStart && planEnd) {
        const params = {
          planStart,
          planEnd,
          workTypeList: workTypes.map(item => {
            if (item.licenseType === "") {
              item.licenseType = [];
            }
            return item;
          })
        };
        const valid = await validateWorkTypeVacation(params);
        if (!valid) {
          this.$dialog.alert({
            title: "提示",
            message: `作业计划时间涉及休假时间，无法按填报的作业类别进行作业，请重新填报。`
          });
          switch (type) {
            case "workType":
              this.formBase.workTypeList = [];
              break;
            case "planStart":
              this.formBase.planStart = "";
              break;
            case "planEnd":
              this.formBase.planEnd = "";
              break;
            default:
              console.log("未匹配到 type");
          }
        }
      }
    },
    async validateWorkTime() {
      const { planStart, planEnd, workTypeList } = this.formBase;
      const workTypeCheck = (workTypeList || []).some(item =>
        [2, 3].includes(item.fireLevel)
      );
      if (workTypeCheck && planStart && planEnd) {
        const params = { planStart, planEnd };
        const valid = await validateWorkTime(params);
        if (valid) {
          this.$dialog.alert({
            title: "提示",
            message: `作业计划时间涉及下班时间，下班后需要重新选择作业审批人员，请知晓。`
          });
        }
      }
    }
  }
};
</script>

<style lang="scss">
.form-box,
.add-work-type-form-box {
  .van-field__label {
    width: auto;
    // white-space: nowrap;
  }
}
.work-unit-popup {
  .content {
    .van-tabs__nav {
      margin: 0 50px 0 40px;
    }
  }
}
</style>

<style lang="scss" scoped>
.form-box {
  padding: 58px 0 0;
  margin: 0 0 16px;
  height: calc(100vh - 58px);
  overflow: auto;
  .form {
    padding: 0 0 60px;
  }
  .van-field__label {
    width: auto;
  }
  .work-type-list-box {
    padding: 0 16px;
    .item-box {
      display: flex;
      justify-content: space-between;
      padding: 10px 8px 10px 12px;
      margin: 0 0 8px;
      background-color: #f5f7fa;
      border-radius: 6px;
      .left-box {
        flex: 1;
        word-break: break-all;
        font-size: 14px;
        color: #8c8f97;
        line-height: 20px;
        padding: 0 2px 0 0;
        .type-name {
          color: #8c8f97;
          margin: 0 0 6px;
        }
        p:nth-child(2) {
          margin: 0 0 2px;
        }
      }
      .close-box {
        display: inline-block;
        width: 13px;
        height: 13px;
        background-color: #aeb3c0;
        color: #efefef;
        font-size: 14px;
        text-align: center;
        line-height: 13px;
        border-radius: 13px;
      }
    }
  }
  .work-link-box {
    padding: 4px 16px 0;
    .item-box {
      display: flex;
      margin: 0 0 8px;
      div {
        flex: 1;
      }
      .left-box {
        flex: initial;
        padding: 2px 4px 0 0;
      }
      .content {
        flex: initial;
        width: 295px;
        font-size: 14px;
        font-weight: 400;
        color: #1676ff;
        line-height: 20px;
      }
    }
    .close-box {
      display: inline-block;
      width: 13px;
      height: 13px;
      background-color: #aeb3c0;
      color: #efefef;
      font-size: 14px;
      text-align: center;
      line-height: 13px;
      border-radius: 13px;
      margin: 2px 0 0 14px;
    }
  }
  .footer-box {
    position: absolute;
    bottom: 0;
    width: calc(100% - 32px);
    padding: 0 16px 16px;
    z-index: 1;
    background-color: #fff;
    .confirm-btn {
      width: 343px;
      height: 44px;
      font-size: 18px;
      font-weight: 500;
    }
  }
}
.work-type-popup {
  height: 100vh;
  overflow: hidden;
  .header {
    // position: relative;
    display: flex;
    // justify-content: space-between;
    padding: 14px 16px 13px;
    .close-icon {
      color: #9496a3;
      font-size: 16px;
    }
    .title {
      font-size: 18px;
      font-weight: 400;
      color: #2e2e4d;
      line-height: 17px;
      flex: 1;
      text-align: center;
      margin: 0 0 0 -16px;
      pointer-events: none;
    }
  }
  .content {
    overflow: auto;
    height: calc(100% - 110px);
  }
  .confirm-btn-box {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 0 16px 16px;
    z-index: 1;
    background-color: #fff;
    .confirm-btn {
      width: 343px;
      height: 44px;
      font-size: 18px;
      font-weight: 500;
    }
  }
}
.add-work-type-form-box {
  .checkbox-box {
    display: inline-flex;
    vertical-align: middle;
  }
  .checkbox-name {
    font-size: 16px;
    font-weight: 500;
    color: #2e2e4d;
    line-height: 22px;
    vertical-align: middle;
    margin: 0 6px 0;
  }
  .collapse-item-box {
    border-bottom: 8px solid #eceef2;
  }
}
.work-unit-popup {
  height: 75vh;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  .content {
    overflow: hidden;
    position: relative;
    height: 100%;
    .title {
      line-height: 44px;
      text-align: center;
    }
    .close-icon {
      position: absolute;
      top: 16px;
      left: 19px;
      z-index: 1;
      color: #9496a3;
      font-size: 12px;
    }
    .confirm-btn-box {
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: 0 16px 16px;
      z-index: 1;
      background-color: #fff;
      .confirm-btn {
        width: 343px;
        height: 44px;
        font-size: 18px;
        font-weight: 500;
      }
    }
    .contractors-list {
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      height: calc(75vh - 110px);
      .contractors-list-item {
        padding: 14px 16px;
        &.active {
          color: $--color-primary;
        }
        &.active-bgc {
          background-color: rgba(22, 118, 255, 0.1);
        }
      }
    }
    .org-tree-container {
      padding: 0 16px;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      height: calc(75vh - 110px);
    }
  }
}
</style>
